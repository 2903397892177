<template>
    <div class="user_model">
        <div class="user_content">
            <div class="user_warp">
                <div class="user_left">
                    <div class="avatar">
                        <img :src="staticDomainURL + userInfo.avatar">
                    </div>
                    <div class="name">{{userInfo.realname}}</div>
                    <!-- <div class="state">正常/请假</div> -->
                </div>
                <div class="user_right">
                    <div class="introduce">
                        <div class="introduce_name">公司职位</div>
                        <div class="introduce_info">{{userInfo.post || '/'}}</div>
                    </div>
                    <div class="introduce">
                        <div class="introduce_name">所属部门</div>
                        <div class="introduce_info">{{userInfo.orgCodeTxt || '/'}}</div>
                    </div>
                    <div class="introduce">
                        <div class="introduce_name">员工编号</div>
                        <div class="introduce_info">{{userInfo.workNo}}</div>
                    </div>
                    <div class="introduce">
                        <div class="introduce_name">生日</div>
                        <div class="introduce_info">{{userInfo.birthday || '/'}}</div>
                    </div>
                    <div class="introduce">
                        <div class="introduce_name">性别</div>
                        <div class="introduce_info">{{userInfo.sex == 1 ? '男': '女'}}</div>
                    </div>
                    <div class="introduce">
                        <div class="introduce_name">通讯电话</div>
                        <div class="introduce_info">{{userInfo.phone}}</div>
                    </div>
                    <div class="introduce">
                        <div class="introduce_name">个人邮箱</div>
                        <div class="introduce_info">{{userInfo.email}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import { USER_INFO } from "@/store/mutation-types"

export default {
    data() {
        return{
          staticDomainURL: window._CONFIG['staticDomainURL'],
          userInfo: Vue.ls.get(USER_INFO)

        }
    }
}
</script>


<style lang="less" scoped>
.user_model{
    width: 100%;
    padding-top: 25px;
    padding-bottom: 25px;
    position: relative;
    // background: url("~@/assets/img/home_bg.png") no-repeat top center / cover;
    background: #f6f6f6;
    .user_content{
        width: 1500px;
        margin: 0 auto;
        z-index: 10;
        .user_warp{
            min-height: 700px;
            background-color: #fff;
            display: flex;
            padding-bottom: 60px;
            .user_left{
                // border-right: 1px solid #BD002E 10000%;
                .avatar{
                    margin: 45px 35px 0px 35px;
                    width: 160px;
                    height: 160px;
                    border-radius: 50%;
                    overflow: hidden;
                    border: none;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .name{
                    margin-top: 30px;
                    text-align: center;
                    color: #000000 100%;
                    font-size: 30px;
                    font-weight: 800;
                }
                .state{
                    margin-top: 30px;
                    text-align: center;
                    color: #000000 100%;
                    font-size: 20px;
                }
            }
            .user_right {
                background: #FFECEC 4800%;
                padding-left: 24px;
                padding-top: 20px;
                .introduce{
                    display: flex;
                    margin-bottom: 10px;
                    .introduce_name {
                        width: 176px;
                        height: 47px;
                        background: #CB859A 10000%;
                        text-align: center;
                        color: #fff;
                        line-height: 47px;
                        font-size: 18px;
                        margin-right: 15px; 
                    }
                    .introduce_info{
                        text-align: left;
                        padding-left: 40px;
                        margin-right: 30px;
                        height: 47px;
                        line-height: 47px;
                        width: 1024px;
                        background: #fff;
                        color: #000000 75%;
                    }
                }
            }
        }
    }
}
</style>

